const React = require("react")
const { LangContext } = require("src/context/lang")
const { PagesContext } = require("src/context/pages")

exports.langWrapper = ({ element, props, ...rest }) => {
  const {
    pageContext: { lang, i18n, pages, pagePath },
  } = props

  return (
    <LangContext.Provider value={{ lang, i18n }}>
      <PagesContext.Provider value={{ pages, currentPath: pagePath }}>
        {element}
      </PagesContext.Provider>
    </LangContext.Provider>
  )
}
