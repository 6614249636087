import { createContext } from "react"

export interface I18nSettings {
  locales: string[]
  defaultLocale: string
}

interface LangContext {
  lang: string
  i18n: I18nSettings
}

export const LangContext = createContext<LangContext>({
  lang: "en",
  i18n: {
    locales: ["en"],
    defaultLocale: "en",
  },
})
