/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.scss"
import { langWrapper } from "./config/langWrapper"
import smoothscroll from "smoothscroll-polyfill"

smoothscroll.polyfill()

export const wrapPageElement = langWrapper

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
