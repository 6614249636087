import { createContext, useContext, useCallback, useMemo } from "react"
import { Page } from "src/pages"
import { LangContext } from "./lang"

export interface PagesContext {
  currentPathWithoutLang: string
  currentPath: string
  pages: Record<Page["id"], Page>
}

export const PagesContext = createContext<PagesContext>({
  pages: {},
  currentPath: "/",
  currentPathWithoutLang: "/",
})

export function usePages() {
  const { pages, currentPath } = useContext(PagesContext)
  const { lang } = useContext(LangContext)

  const getPagesWithName = useCallback(
    (query: Record<Page["id"], string>) => {
      const result: Record<Page["id"], Page> = {}

      for (const [id, name] of Object.entries(query)) {
        const existingPage = pages[id]

        if (!existingPage) {
          result[id] = {
            url: `/${id}`,
            id: id,
            name,
          }
          continue
        }

        result[id] = {
          ...existingPage,
          name,
        }
      }

      return result
    },
    [pages],
  )

  const getArrayPagesWithName = useCallback(
    (query: Record<Page["id"], string>) => {
      return Object.values(getPagesWithName(query))
    },
    [getPagesWithName],
  )

  const getPage = useCallback(
    (query: Page["id"]) => {
      return {
        id: query,
        url: `/${query}`,
        ...pages[query],
      }
    },
    [pages],
  )

  const realCurrentPath = useMemo(() => {
    return currentPath.endsWith("*") ? currentPath.slice(0, -1) : currentPath
  }, [currentPath])

  return {
    currentPathWithoutLang: realCurrentPath.replace(`/${lang}`, "") || "/",
    currentPath: realCurrentPath,
    pages,
    getPagesWithName,
    getArrayPagesWithName,
    getPage,
  }
}
